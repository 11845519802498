import '@rainbow-me/rainbowkit/styles.css';
import { ChakraProvider } from '@chakra-ui/react';
import { LensConfig, staging, LensProvider } from '@lens-protocol/react';
import { localStorage } from '@lens-protocol/react/web';
import { bindings as wagmiBindings } from '@lens-protocol/wagmi';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { EthComponentsSettingsContext, IEthComponentsSettings } from 'eth-components/models';
import { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import { FC, ReactNode, Suspense } from 'react';
import { WagmiConfig, createClient, configureChains } from 'wagmi';
import { polygonMumbai } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

import { ErrorBoundary, ErrorFallback } from '~common/components';
import Layout from '~~/components/main/Layout';
import { BLOCKNATIVE_DAPPID } from '~~/config/nextjsApp.config';
import theme from '~~/config/theme';

const lensConfig: LensConfig = {
  bindings: wagmiBindings(),
  environment: staging,
  storage: localStorage(),
};

// create eth components context for options and API keys
const ethComponentsSettings: IEthComponentsSettings = {
  apiKeys: {
    BlocknativeDappId: BLOCKNATIVE_DAPPID,
  },
};

const { chains, provider } = configureChains(
  [polygonMumbai],
  [infuraProvider({ apiKey: process.env.NEXT_PUBLIC_KEY_INFURA }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'SIGUE',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const ProviderWrapper: FC<{ children?: ReactNode }> = (props) => {
  return (
    <EthComponentsSettingsContext.Provider value={ethComponentsSettings}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <LensProvider config={lensConfig}>
            <ChakraProvider theme={theme}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>{props.children}</ErrorBoundary>
              </ErrorBoundary>
            </ChakraProvider>
          </LensProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </EthComponentsSettingsContext.Provider>
  );
};

/**
 * ### Summary
 * The main app component is {@see MainPage} `components/routes/main/MaingPage.tsx`
 * This component sets up all the providers, Suspense and Error handling
 * @returns
 */
const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps> = ({ Component, ...props }) => {
  console.log('loading app...');

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ProviderWrapper>
        <Suspense fallback={<div />}>
          <Layout>
            <Component {...props.pageProps} />
          </Layout>
        </Suspense>
      </ProviderWrapper>
    </ErrorBoundary>
  );
};

export default MyApp;
