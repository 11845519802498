import { useNetwork } from 'wagmi';

import { appContractsConfig } from '~common/config';

const useContractsAddresses = () => {
  const { chain } = useNetwork();
  const appContracts = appContractsConfig();

  return {
    sigueAddress:
      chain?.unsupported || !chain?.id ? undefined : (appContracts.Sigue.config[chain.id].address as `0x${string}`),
    sigueERC20Address:
      chain?.unsupported || !chain?.id
        ? undefined
        : (appContracts.SigueERC20.config[chain.id].address as `0x${string}`),
  };
};

export default useContractsAddresses;
