import { Box, Center, Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';

import Sidebar from '~~/components/main/Sidebar';

function Layout({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  return (
    <Flex h="100vh" overflow="hidden">
      <Sidebar />

      <Center
        position="relative"
        flex={1}
        overflowX="hidden"
        overflowY="auto"
        sx={{ backgroundImage: 'url("/bg.svg")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <AnimatePresence mode="wait" initial={false}>
          <Box
            key={router.pathname}
            py={16}
            as={motion.div}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1, transition: { duration: 0.1 } }}
            exit={{ opacity: 0, scale: 1.05 }}>
            {children}
          </Box>
        </AnimatePresence>
      </Center>
    </Flex>
  );
}
export default Layout;
