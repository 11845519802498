import { Box, Flex, Link, VStack, Icon } from '@chakra-ui/react';
import { IconUserPlus, IconHome, IconShoppingCart, IconCoins, IconInfoSquareRounded } from '@tabler/icons-react';
import NextLink from 'next/link';

import Logo from '../common/Logo';

import LensAccountButton from './LensAccountButton';

import NavLink from '~~/components/common/NavLink';
import WalletButton from '~~/components/main/WalletButton';

function NavBar() {
  return (
    <Box
      sx={{
        pos: 'sticky',
        left: 0,
        top: 0,
        bottom: 0,
        height: 'auto',
        zIndex: 100,
        width: '20rem',
        marginInline: 'auto',
        background: 'linear-gradient(315deg, #080721, #0a0928)',
        boxShadow: `-20px -20px 60px #08071f,
             20px: '20px 60px #0a092b`,
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          height: 'full',
        }}>
        <Flex justify="space-between" flexDir="column" h="full" py={10}>
          <Link
            as={NextLink}
            href="/"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            fontSize="lg"
            opacity={1}
            color="indigo.50">
            <Box w={100} h={100}>
              <Logo />
            </Box>
          </Link>

          <VStack spacing={0} textAlign="left" alignItems="flex-start" color="indigo.200">
            <NavLink href="/">
              <Icon as={IconHome} mr={3} bottom={-0.5} pos="relative" />
              Home
            </NavLink>
            <NavLink href="/stake">
              <Icon as={IconCoins} mr={3} bottom={-0.5} pos="relative" />
              Stake
            </NavLink>
            <NavLink href="/follow">
              <Icon as={IconUserPlus} mr={3} bottom={-0.5} pos="relative" />
              Follow to earn
            </NavLink>
            <NavLink href="/buy">
              <Icon as={IconShoppingCart} mr={3} bottom={-0.5} pos="relative" />
              Buy SIGUE
            </NavLink>
            <NavLink href="/about">
              <Icon as={IconInfoSquareRounded} mr={3} bottom={-0.5} pos="relative" />
              About
            </NavLink>
          </VStack>

          <VStack spacing={2} w="full">
            <LensAccountButton />
            <WalletButton />
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
}

export default NavBar;
