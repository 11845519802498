import { Box, Button } from '@chakra-ui/react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { TbAlertTriangle } from 'react-icons/tb';
import { useAccount, useBalance } from 'wagmi';

import useContractsAddresses from '~~/hooks/useContractsAddresses';
import { formatCurrency } from '~~/utils/formatNumbers';

const WalletButton = () => {
  const { sigueERC20Address } = useContractsAddresses();

  const account = useAccount();
  const balance = useBalance({
    address: account.address,
    token: sigueERC20Address,
    watch: true,
  });

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
        return (
          <Box
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}>
            {(() => {
              if (!connected) {
                return (
                  <Button onClick={openConnectModal} variant="ghost" colorScheme="whiteAlpha" size="sm">
                    Connect Wallet
                  </Button>
                );
              }
              if (chain.unsupported) {
                return (
                  <Button
                    size="sm"
                    onClick={openChainModal}
                    colorScheme="red"
                    variant="ghost"
                    leftIcon={<TbAlertTriangle size={16} />}>
                    Wrong network
                  </Button>
                );
              }
              return (
                <Button
                  onClick={openAccountModal}
                  variant="ghost"
                  colorScheme="whiteAlpha"
                  size="sm"
                  leftIcon={
                    chain.hasIcon ? (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 16,
                          height: 16,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                        }}>
                        {chain.iconUrl && (
                          <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 16, height: 16 }} />
                        )}
                      </div>
                    ) : undefined
                  }>
                  {account.displayName}{' '}
                  {balance.isLoading ? '...' : formatCurrency(balance.data?.formatted || 0, 'SIGUE')}
                </Button>
              );
            })()}
          </Box>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletButton;
