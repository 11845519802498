import {
  Box,
  Button,
  Link,
  Icon,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Text,
} from '@chakra-ui/react';
import { useActiveProfile, useWalletLogin, useWalletLogout } from '@lens-protocol/react';
import { TbExternalLink } from 'react-icons/tb';
import { useAccount, useSigner } from 'wagmi';

import { useSigueGetProfileId } from '~~/generated/wagmi';
import useContractsAddresses from '~~/hooks/useContractsAddresses';
import LensIcon from '~~/icons/LensIcon';

export default function LensAccountButton() {
  const { sigueAddress } = useContractsAddresses();
  const wallet = useAccount();
  const lensActiveProfile = useActiveProfile();
  const lensLogin = useWalletLogin();
  const lensLogout = useWalletLogout();
  const { data: signer } = useSigner();

  const lensProfileIdQuery = useSigueGetProfileId({
    args: [wallet.address || '0x0'],
    watch: true,
    address: sigueAddress,
  });

  if (!wallet.address || !signer) {
    return <></>;
  }

  const addressHasNoLensAccount =
    wallet.address && lensProfileIdQuery.isFetched && (!lensProfileIdQuery.data || lensProfileIdQuery.isError);

  const activeLensDoesntMatchWallet =
    lensActiveProfile.data &&
    lensProfileIdQuery.data &&
    lensActiveProfile.data?.id !== lensProfileIdQuery.data?.toHexString();

  const onLogin = async () => {
    if (signer) {
      if (activeLensDoesntMatchWallet) {
        await lensLogout.logout();
      }
      await lensLogin.login(signer);
    }
  };

  if (
    !lensActiveProfile.data ||
    addressHasNoLensAccount ||
    lensActiveProfile.data?.id !== lensProfileIdQuery.data?.toHexString()
  ) {
    return (
      <Box>
        <Popover isOpen={addressHasNoLensAccount}>
          <PopoverAnchor>
            <Button
              variant="ghost"
              onClick={onLogin}
              leftIcon={<LensIcon />}
              colorScheme="whiteAlpha"
              isLoading={lensLogin.isPending || lensProfileIdQuery.isLoading || lensLogout.isPending}
              isDisabled={addressHasNoLensAccount}
              size="sm">
              Login with Lens
            </Button>
          </PopoverAnchor>
          <PopoverContent bgColor="red.50" color="red.900">
            <PopoverArrow bgColor="red.50" />
            <PopoverHeader>
              <Text fontWeight="bold">No Lens Account</Text>
            </PopoverHeader>
            <PopoverBody>
              It looks like you don&apos;t have a lens account.{' '}
              <Link href="https://testnet.lenster.xyz/" isExternal fontWeight="bold">
                Create one here <Icon as={TbExternalLink} mb="-2px" />
              </Link>{' '}
              and then come back to this page
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    );
  }

  return (
    <Link
      href={`https://testnet.lenster.xyz/u/${lensActiveProfile.data?.handle}`}
      isExternal
      color="white"
      fontWeight="600"
      opacity={0.5}>
      <LensIcon mr={2} pb={0.5} />
      {lensActiveProfile.data?.handle}
    </Link>
  );
}
