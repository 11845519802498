import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    p: 12,
    borderRadius: 24,
    // boxShadow: '2xl',
    // backgroundColor: 'rgba(255, 255, 255, 0.8)',

    background: 'rgba(255, 255, 255, 0.7)',
    // boxShadow: 'none',
    // background: '#f7fdf9',
    boxShadow: `20px 20px 60px rgba(0, 0, 0, 0.8)
    -20px -20px 60px rgba(0, 0, 0, 0.8)`,
  },
});

export const cardTheme = defineMultiStyleConfig({ baseStyle });
