/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { invariant } from 'ts-invariant';

import hardhatDeployedContractsJson from '~common/generated/hardhat_contracts.json';
import {
  deployedHardhatContractsJsonSchema,
  TBasicContractDeployment,
  TContractMapWithAbi,
  TDeployedHardhatContractsJson,
} from 'eth-hooks/models';

import { merge } from 'merge-anything';

/**
 * #### Summary
 * This function is used to extract the contract data from hardhat deployment json files
 *
 * @internal
 * @category ContractAppContext
 * @param configJson {@link TDeployedHardhatContractsJson}
 * @returns
 */
const extractHardhatContracts = (configJson: TDeployedHardhatContractsJson): TContractMapWithAbi => {
  const parse = deployedHardhatContractsJsonSchema.safeParse(configJson);
  if (!parse.success) {
    console.error('Invalid deployment hardhat_contracts.json TDeployedHardhatContractsJson', parse.error);
  }

  const contractData: TContractMapWithAbi = {};
  for (const chainIdStr in configJson) {
    const chainId = parseInt(chainIdStr);
    if (chainId == null || isNaN(chainId)) continue;

    const deployedDataByNetwork = Object.values(configJson[chainId]).filter(
      (f) => parseInt(f?.chainId) === chainId
    )?.[0];
    if (deployedDataByNetwork?.chainId != null) {
      for (const contractName in deployedDataByNetwork.contracts) {
        const config: TBasicContractDeployment = {
          [chainId]: { address: deployedDataByNetwork.contracts[contractName].address, chainId },
        };

        const abi = deployedDataByNetwork.contracts[contractName].abi;
        if (abi && abi?.length > 0) {
          contractData[contractName] = merge(contractData[contractName] ?? {}, { abi: abi });
        }
        contractData[contractName] = merge({ ...contractData[contractName] }, { config });
      }
    }
  }

  return contractData;
};

const SigueContract = extractHardhatContracts(hardhatDeployedContractsJson)['Sigue'];
const SigueERC20Contract = extractHardhatContracts(hardhatDeployedContractsJson)['SigueERC20'];

/**
 * ⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️
 * ### Instructions
 * 1. edit externalContracts.config.ts to add your external contract addresses.
 * 2. edit `appContractsConfig` function below and add them to the list
 * 3. run `yarn contracts:build` to generate types for contracts
 * 4. run `yarn deploy` to generate hardhat_contracts.json
 *
 * ### Summary
 * - called  by useAppContracts
 * @returns
 */
export const appContractsConfig = () => {
  try {
    const result = {
      // --------------------------------------------------
      // 🙋🏽‍♂️ Contracts examples either using hardhat or foundry
      // --------------------------------------------------
      SigueERC20: SigueERC20Contract,
      Sigue: SigueContract,
      // --------------------------------------------------
      // 🙋🏽‍♂️ Add your external contracts here, make sure to define the address in `externalContractsConfig.ts`Í
      // --------------------------------------------------
      // DAI: createConnectorForExternalContract('DAI', externalContracts.DAI__factory, externalContractsAddressMap),
      // --------------------------------------------------
      // 🙋🏽‍♂️ Add your external abi here (unverified contracts)`
      // --------------------------------------------------
      // YourContractFromAbi: createConnectorForExternalAbi(
      //   'YourContract',
      //   {
      //     [1235]: {
      //       address: 'xxx',
      //     },
      //   },
      //   toolkitContracts.YourContract__factory.abi
      //   // optional if you have a connect function:  externalContracts.YourContract__factory.connect
      // ),
    } as const;

    return result;
  } catch (e) {
    invariant.error(
      '❌ appContractsConfig: ERROR with loading contracts please run `yarn contracts:build or yarn contracts:rebuild`.  Then run `yarn deploy`!'
    );
    invariant.error(e);
    throw e;
  }
};
