// Generated by @wagmi/cli@0.1.5 on 3/15/2023 at 12:40:01 AM
import {
  useContract,
  UseContractConfig,
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import { WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ILensHub
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iLensHubABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileIds', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'datas', internalType: 'bytes[]', type: 'bytes[]' },
    ],
    name: 'follow',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getFollowNFT',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_owner', internalType: 'address', type: 'address' },
      { name: '_index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Sigue
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const sigueABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: '_ERC20address', internalType: 'address', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'buyer', internalType: 'address', type: 'address', indexed: false },
      { name: 'amountOfETH', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountOfTokens', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'BuyTokens',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'FollowRewarded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'seller', internalType: 'address', type: 'address', indexed: false },
      { name: 'amountOfTokens', internalType: 'uint256', type: 'uint256', indexed: false },
      { name: 'amountOfETH', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'SellTokens',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'user', internalType: 'address', type: 'address', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'TokensStaked',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'TOKENS_PER_ETH',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  { stateMutability: 'payable', type: 'function', inputs: [], name: 'buyTokens', outputs: [] },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [{ name: '_address', internalType: 'address', type: 'address' }],
    name: 'getERC721',
    outputs: [{ name: '', internalType: 'contract ERC721', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getFollowNftAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_follower', internalType: 'address', type: 'address' },
      { name: '_followed', internalType: 'address', type: 'address' },
    ],
    name: 'getHasBeenRewarded',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_address', internalType: 'address', type: 'address' }],
    name: 'getProfileId',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_cursor', internalType: 'uint256', type: 'uint256' },
      { name: '_howMany', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getStakingProfiles',
    outputs: [
      { name: 'values', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'newCursor', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [],
    name: 'getTokensPerEth',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: '_sender', internalType: 'address', type: 'address' },
      { name: '_address', internalType: 'address', type: 'address' },
    ],
    name: 'isFollowing',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'renounceOwnership', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'rewardedMap',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'sellTokens',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'sigueERC20',
    outputs: [{ name: '', internalType: 'contract SigueERC20', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_amount', internalType: 'uint256', type: 'uint256' }],
    name: 'stakeTokensForFollows',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'stakingBalances',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_address', internalType: 'address', type: 'address' }],
    name: 'verifyFollow',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SigueERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const sigueErc20ABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'renounceOwnership', outputs: [] },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ownable
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ownableABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  { stateMutability: 'nonpayable', type: 'function', inputs: [], name: 'renounceOwnership', outputs: [] },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC721
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc721ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'name_', internalType: 'string', type: 'string' },
      { name: 'symbol_', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'operator', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC721
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc721ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'operator', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: 'operator', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: '_approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC721Receiver
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc721ReceiverABI = [
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'onERC721Received',
    outputs: [{ name: '', internalType: 'bytes4', type: 'bytes4' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'name_', internalType: 'string', type: 'string' },
      { name: 'symbol_', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20ABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC165
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc165ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC165
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc165ABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC721Metadata
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc721MetadataABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'operator', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: 'operator', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: '_approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IERC20Metadata
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ierc20MetadataABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'spender', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'value', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LensHub
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export const lensHubABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'followNFTImpl', internalType: 'address', type: 'address' },
      { name: 'collectNFTImpl', internalType: 'address', type: 'address' },
    ],
  },
  { type: 'error', inputs: [], name: 'CallerNotCollectNFT' },
  { type: 'error', inputs: [], name: 'CallerNotFollowNFT' },
  { type: 'error', inputs: [], name: 'CannotInitImplementation' },
  { type: 'error', inputs: [], name: 'DispatcherNotSet' },
  { type: 'error', inputs: [], name: 'EmergencyAdminCannotUnpause' },
  { type: 'error', inputs: [], name: 'InitParamsInvalid' },
  { type: 'error', inputs: [], name: 'Initialized' },
  { type: 'error', inputs: [], name: 'NotGovernance' },
  { type: 'error', inputs: [], name: 'NotGovernanceOrEmergencyAdmin' },
  { type: 'error', inputs: [], name: 'NotOwnerOrApproved' },
  { type: 'error', inputs: [], name: 'NotProfileOwner' },
  { type: 'error', inputs: [], name: 'NotProfileOwnerOrDispatcher' },
  { type: 'error', inputs: [], name: 'Paused' },
  { type: 'error', inputs: [], name: 'ProfileCreatorNotWhitelisted' },
  { type: 'error', inputs: [], name: 'ProfileImageURILengthInvalid' },
  { type: 'error', inputs: [], name: 'PublicationDoesNotExist' },
  { type: 'error', inputs: [], name: 'PublishingPaused' },
  { type: 'error', inputs: [], name: 'SignatureExpired' },
  { type: 'error', inputs: [], name: 'SignatureInvalid' },
  { type: 'error', inputs: [], name: 'ZeroSpender' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address', indexed: true },
      { name: 'operator', internalType: 'address', type: 'address', indexed: true },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256', indexed: true },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'burn',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      {
        name: 'sig',
        internalType: 'struct DataTypes.EIP712Signature',
        type: 'tuple',
        components: [
          { name: 'v', internalType: 'uint8', type: 'uint8' },
          { name: 'r', internalType: 'bytes32', type: 'bytes32' },
          { name: 's', internalType: 'bytes32', type: 'bytes32' },
          { name: 'deadline', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'burnWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'collect',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.CollectWithSigData',
        type: 'tuple',
        components: [
          { name: 'collector', internalType: 'address', type: 'address' },
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'pubId', internalType: 'uint256', type: 'uint256' },
          { name: 'data', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'collectWithSig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.CommentData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'comment',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.CommentWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'commentWithSig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.CommentWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'commentWithSig_Dispatcher',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.CreateProfileData',
        type: 'tuple',
        components: [
          { name: 'to', internalType: 'address', type: 'address' },
          { name: 'handle', internalType: 'string', type: 'string' },
          { name: 'imageURI', internalType: 'string', type: 'string' },
          { name: 'followModule', internalType: 'address', type: 'address' },
          { name: 'followModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'followNFTURI', internalType: 'string', type: 'string' },
        ],
      },
    ],
    name: 'createProfile',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'wallet', internalType: 'address', type: 'address' }],
    name: 'defaultProfile',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
      { name: 'collectNFTId', internalType: 'uint256', type: 'uint256' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
    ],
    name: 'emitCollectNFTTransferEvent',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'followNFTId', internalType: 'uint256', type: 'uint256' },
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
    ],
    name: 'emitFollowNFTTransferEvent',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'exists',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileIds', internalType: 'uint256[]', type: 'uint256[]' },
      { name: 'datas', internalType: 'bytes[]', type: 'bytes[]' },
    ],
    name: 'follow',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.FollowWithSigData',
        type: 'tuple',
        components: [
          { name: 'follower', internalType: 'address', type: 'address' },
          { name: 'profileIds', internalType: 'uint256[]', type: 'uint256[]' },
          { name: 'datas', internalType: 'bytes[]', type: 'bytes[]' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'followWithSig',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getCollectModule',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getCollectNFT',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getCollectNFTImpl',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getContentURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getDispatcher',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getDomainSeparator',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getFollowModule',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getFollowNFT',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getFollowNFTImpl',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getFollowNFTURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getGovernance',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getHandle',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getProfile',
    outputs: [
      {
        name: '',
        internalType: 'struct DataTypes.ProfileStruct',
        type: 'tuple',
        components: [
          { name: 'pubCount', internalType: 'uint256', type: 'uint256' },
          { name: 'followModule', internalType: 'address', type: 'address' },
          { name: 'followNFT', internalType: 'address', type: 'address' },
          { name: 'handle', internalType: 'string', type: 'string' },
          { name: 'imageURI', internalType: 'string', type: 'string' },
          { name: 'followNFTURI', internalType: 'string', type: 'string' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'handle', internalType: 'string', type: 'string' }],
    name: 'getProfileIdByHandle',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPub',
    outputs: [
      {
        name: '',
        internalType: 'struct DataTypes.PublicationStruct',
        type: 'tuple',
        components: [
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectNFT', internalType: 'address', type: 'address' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'getPubCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPubPointer',
    outputs: [
      { name: '', internalType: 'uint256', type: 'uint256' },
      { name: '', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getPubType',
    outputs: [{ name: '', internalType: 'enum DataTypes.PubType', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'pubId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getReferenceModule',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getState',
    outputs: [{ name: '', internalType: 'enum DataTypes.ProtocolState', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'name', internalType: 'string', type: 'string' },
      { name: 'symbol', internalType: 'string', type: 'string' },
      { name: 'newGovernance', internalType: 'address', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'collectModule', internalType: 'address', type: 'address' }],
    name: 'isCollectModuleWhitelisted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'followModule', internalType: 'address', type: 'address' }],
    name: 'isFollowModuleWhitelisted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'profileCreator', internalType: 'address', type: 'address' }],
    name: 'isProfileCreatorWhitelisted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'referenceModule', internalType: 'address', type: 'address' }],
    name: 'isReferenceModuleWhitelisted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'mintTimestampOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.MirrorData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'mirror',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.MirrorWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'mirrorWithSig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.MirrorWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'profileIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'pubIdPointed', internalType: 'uint256', type: 'uint256' },
          { name: 'referenceModuleData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'mirrorWithSig_Dispatcher',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      {
        name: 'sig',
        internalType: 'struct DataTypes.EIP712Signature',
        type: 'tuple',
        components: [
          { name: 'v', internalType: 'uint8', type: 'uint8' },
          { name: 'r', internalType: 'bytes32', type: 'bytes32' },
          { name: 's', internalType: 'bytes32', type: 'bytes32' },
          { name: 'deadline', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'permit',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
      {
        name: 'sig',
        internalType: 'struct DataTypes.EIP712Signature',
        type: 'tuple',
        components: [
          { name: 'v', internalType: 'uint8', type: 'uint8' },
          { name: 'r', internalType: 'bytes32', type: 'bytes32' },
          { name: 's', internalType: 'bytes32', type: 'bytes32' },
          { name: 'deadline', internalType: 'uint256', type: 'uint256' },
        ],
      },
    ],
    name: 'permitForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.PostData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
        ],
      },
    ],
    name: 'post',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.PostWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'postWithSig',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.PostWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'contentURI', internalType: 'string', type: 'string' },
          { name: 'collectModule', internalType: 'address', type: 'address' },
          { name: 'collectModuleInitData', internalType: 'bytes', type: 'bytes' },
          { name: 'referenceModule', internalType: 'address', type: 'address' },
          { name: 'referenceModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'postWithSig_Dispatcher',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'profileId', internalType: 'uint256', type: 'uint256' }],
    name: 'setDefaultProfile',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.SetDefaultProfileWithSigData',
        type: 'tuple',
        components: [
          { name: 'wallet', internalType: 'address', type: 'address' },
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'setDefaultProfileWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'dispatcher', internalType: 'address', type: 'address' },
    ],
    name: 'setDispatcher',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.SetDispatcherWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'dispatcher', internalType: 'address', type: 'address' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'setDispatcherWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newEmergencyAdmin', internalType: 'address', type: 'address' }],
    name: 'setEmergencyAdmin',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'followModule', internalType: 'address', type: 'address' },
      { name: 'followModuleInitData', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'setFollowModule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.SetFollowModuleWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'followModule', internalType: 'address', type: 'address' },
          { name: 'followModuleInitData', internalType: 'bytes', type: 'bytes' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'setFollowModuleWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'followNFTURI', internalType: 'string', type: 'string' },
    ],
    name: 'setFollowNFTURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.SetFollowNFTURIWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'followNFTURI', internalType: 'string', type: 'string' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'setFollowNFTURIWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newGovernance', internalType: 'address', type: 'address' }],
    name: 'setGovernance',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileId', internalType: 'uint256', type: 'uint256' },
      { name: 'imageURI', internalType: 'string', type: 'string' },
    ],
    name: 'setProfileImageURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: 'vars',
        internalType: 'struct DataTypes.SetProfileImageURIWithSigData',
        type: 'tuple',
        components: [
          { name: 'profileId', internalType: 'uint256', type: 'uint256' },
          { name: 'imageURI', internalType: 'string', type: 'string' },
          {
            name: 'sig',
            internalType: 'struct DataTypes.EIP712Signature',
            type: 'tuple',
            components: [
              { name: 'v', internalType: 'uint8', type: 'uint8' },
              { name: 'r', internalType: 'bytes32', type: 'bytes32' },
              { name: 's', internalType: 'bytes32', type: 'bytes32' },
              { name: 'deadline', internalType: 'uint256', type: 'uint256' },
            ],
          },
        ],
      },
    ],
    name: 'setProfileImageURIWithSig',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newState', internalType: 'enum DataTypes.ProtocolState', type: 'uint8' }],
    name: 'setState',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'sigNonces',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenDataOf',
    outputs: [
      {
        name: '',
        internalType: 'struct IERC721Time.TokenData',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          { name: 'mintTimestamp', internalType: 'uint96', type: 'uint96' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'collectModule', internalType: 'address', type: 'address' },
      { name: 'whitelist', internalType: 'bool', type: 'bool' },
    ],
    name: 'whitelistCollectModule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'followModule', internalType: 'address', type: 'address' },
      { name: 'whitelist', internalType: 'bool', type: 'bool' },
    ],
    name: 'whitelistFollowModule',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'profileCreator', internalType: 'address', type: 'address' },
      { name: 'whitelist', internalType: 'bool', type: 'bool' },
    ],
    name: 'whitelistProfileCreator',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'referenceModule', internalType: 'address', type: 'address' },
      { name: 'whitelist', internalType: 'bool', type: 'bool' },
    ],
    name: 'whitelistReferenceModule',
    outputs: [],
  },
] as const

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export const lensHubAddress = {
  80001: '0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44',
} as const

/**
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export const lensHubConfig = { address: lensHubAddress, abi: lensHubABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link iLensHubABI}__.
 */
export function useILensHub(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: iLensHubABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iLensHubABI}__.
 */
export function useILensHubRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof iLensHubABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: iLensHubABI, ...config } as UseContractReadConfig<typeof iLensHubABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iLensHubABI}__ and `functionName` set to `"getFollowNFT"`.
 */
export function useILensHubGetFollowNft(
  config: Omit<UseContractReadConfig<typeof iLensHubABI, 'getFollowNFT'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: iLensHubABI, functionName: 'getFollowNFT', ...config } as UseContractReadConfig<
    typeof iLensHubABI,
    'getFollowNFT'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link iLensHubABI}__ and `functionName` set to `"tokenOfOwnerByIndex"`.
 */
export function useILensHubTokenOfOwnerByIndex(
  config: Omit<UseContractReadConfig<typeof iLensHubABI, 'tokenOfOwnerByIndex'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: iLensHubABI, functionName: 'tokenOfOwnerByIndex', ...config } as UseContractReadConfig<
    typeof iLensHubABI,
    'tokenOfOwnerByIndex'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iLensHubABI}__.
 */
export function useILensHubWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof iLensHubABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof iLensHubABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof iLensHubABI, TFunctionName>({ abi: iLensHubABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link iLensHubABI}__ and `functionName` set to `"follow"`.
 */
export function useILensHubFollow<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof iLensHubABI, 'follow'>['abi'], 'follow'> & {
        functionName?: 'follow'
      }
    : UseContractWriteConfig<TMode, typeof iLensHubABI, 'follow'> & {
        abi?: never
        functionName?: 'follow'
      } = {} as any
) {
  return useContractWrite<TMode, typeof iLensHubABI, 'follow'>({
    abi: iLensHubABI,
    functionName: 'follow',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iLensHubABI}__.
 */
export function usePrepareILensHubWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof iLensHubABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: iLensHubABI, ...config } as UsePrepareContractWriteConfig<
    typeof iLensHubABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link iLensHubABI}__ and `functionName` set to `"follow"`.
 */
export function usePrepareILensHubFollow(
  config: Omit<UsePrepareContractWriteConfig<typeof iLensHubABI, 'follow'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: iLensHubABI,
    functionName: 'follow',
    ...config,
  } as UsePrepareContractWriteConfig<typeof iLensHubABI, 'follow'>)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link sigueABI}__.
 */
export function useSigue(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: sigueABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__.
 */
export function useSigueRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof sigueABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: sigueABI, ...config } as UseContractReadConfig<typeof sigueABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"TOKENS_PER_ETH"`.
 */
export function useSigueTokensPerEth(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'TOKENS_PER_ETH'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'TOKENS_PER_ETH', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'TOKENS_PER_ETH'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getERC721"`.
 */
export function useSigueGetErc721(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getERC721'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getERC721', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getERC721'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getFollowNftAddress"`.
 */
export function useSigueGetFollowNftAddress(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getFollowNftAddress'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getFollowNftAddress', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getFollowNftAddress'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getHasBeenRewarded"`.
 */
export function useSigueGetHasBeenRewarded(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getHasBeenRewarded'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getHasBeenRewarded', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getHasBeenRewarded'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getProfileId"`.
 */
export function useSigueGetProfileId(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getProfileId'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getProfileId', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getProfileId'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getStakingProfiles"`.
 */
export function useSigueGetStakingProfiles(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getStakingProfiles'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getStakingProfiles', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getStakingProfiles'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"getTokensPerEth"`.
 */
export function useSigueGetTokensPerEth(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'getTokensPerEth'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'getTokensPerEth', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'getTokensPerEth'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"isFollowing"`.
 */
export function useSigueIsFollowing(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'isFollowing'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'isFollowing', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'isFollowing'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"owner"`.
 */
export function useSigueOwner(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'owner'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'owner', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'owner'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"rewardedMap"`.
 */
export function useSigueRewardedMap(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'rewardedMap'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'rewardedMap', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'rewardedMap'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"sigueERC20"`.
 */
export function useSigueSigueErc20(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'sigueERC20'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'sigueERC20', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'sigueERC20'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"stakingBalances"`.
 */
export function useSigueStakingBalances(
  config: Omit<UseContractReadConfig<typeof sigueABI, 'stakingBalances'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueABI, functionName: 'stakingBalances', ...config } as UseContractReadConfig<
    typeof sigueABI,
    'stakingBalances'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__.
 */
export function useSigueWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof sigueABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, TFunctionName>({ abi: sigueABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"buyTokens"`.
 */
export function useSigueBuyTokens<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueABI, 'buyTokens'>['abi'], 'buyTokens'> & {
        functionName?: 'buyTokens'
      }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'buyTokens'> & {
        abi?: never
        functionName?: 'buyTokens'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'buyTokens'>({
    abi: sigueABI,
    functionName: 'buyTokens',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useSigueRenounceOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueABI, 'renounceOwnership'>['abi'],
        'renounceOwnership'
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'renounceOwnership'> & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'renounceOwnership'>({
    abi: sigueABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"sellTokens"`.
 */
export function useSigueSellTokens<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueABI, 'sellTokens'>['abi'], 'sellTokens'> & {
        functionName?: 'sellTokens'
      }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'sellTokens'> & {
        abi?: never
        functionName?: 'sellTokens'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'sellTokens'>({
    abi: sigueABI,
    functionName: 'sellTokens',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"stakeTokensForFollows"`.
 */
export function useSigueStakeTokensForFollows<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueABI, 'stakeTokensForFollows'>['abi'],
        'stakeTokensForFollows'
      > & { functionName?: 'stakeTokensForFollows' }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'stakeTokensForFollows'> & {
        abi?: never
        functionName?: 'stakeTokensForFollows'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'stakeTokensForFollows'>({
    abi: sigueABI,
    functionName: 'stakeTokensForFollows',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useSigueTransferOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueABI, 'transferOwnership'>['abi'],
        'transferOwnership'
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'transferOwnership'> & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'transferOwnership'>({
    abi: sigueABI,
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"verifyFollow"`.
 */
export function useSigueVerifyFollow<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueABI, 'verifyFollow'>['abi'],
        'verifyFollow'
      > & { functionName?: 'verifyFollow' }
    : UseContractWriteConfig<TMode, typeof sigueABI, 'verifyFollow'> & {
        abi?: never
        functionName?: 'verifyFollow'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueABI, 'verifyFollow'>({
    abi: sigueABI,
    functionName: 'verifyFollow',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__.
 */
export function usePrepareSigueWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: sigueABI, ...config } as UsePrepareContractWriteConfig<
    typeof sigueABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"buyTokens"`.
 */
export function usePrepareSigueBuyTokens(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, 'buyTokens'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'buyTokens',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'buyTokens'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareSigueRenounceOwnership(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, 'renounceOwnership'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"sellTokens"`.
 */
export function usePrepareSigueSellTokens(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, 'sellTokens'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'sellTokens',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'sellTokens'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"stakeTokensForFollows"`.
 */
export function usePrepareSigueStakeTokensForFollows(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sigueABI, 'stakeTokensForFollows'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'stakeTokensForFollows',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'stakeTokensForFollows'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareSigueTransferOwnership(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, 'transferOwnership'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueABI}__ and `functionName` set to `"verifyFollow"`.
 */
export function usePrepareSigueVerifyFollow(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueABI, 'verifyFollow'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueABI,
    functionName: 'verifyFollow',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueABI, 'verifyFollow'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__.
 */
export function useSigueEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof sigueABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, ...config } as UseContractEventConfig<typeof sigueABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__ and `eventName` set to `"BuyTokens"`.
 */
export function useSigueBuyTokensEvent(
  config: Omit<UseContractEventConfig<typeof sigueABI, 'BuyTokens'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, eventName: 'BuyTokens', ...config } as UseContractEventConfig<
    typeof sigueABI,
    'BuyTokens'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__ and `eventName` set to `"FollowRewarded"`.
 */
export function useSigueFollowRewardedEvent(
  config: Omit<UseContractEventConfig<typeof sigueABI, 'FollowRewarded'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, eventName: 'FollowRewarded', ...config } as UseContractEventConfig<
    typeof sigueABI,
    'FollowRewarded'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useSigueOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof sigueABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, eventName: 'OwnershipTransferred', ...config } as UseContractEventConfig<
    typeof sigueABI,
    'OwnershipTransferred'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__ and `eventName` set to `"SellTokens"`.
 */
export function useSigueSellTokensEvent(
  config: Omit<UseContractEventConfig<typeof sigueABI, 'SellTokens'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, eventName: 'SellTokens', ...config } as UseContractEventConfig<
    typeof sigueABI,
    'SellTokens'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueABI}__ and `eventName` set to `"TokensStaked"`.
 */
export function useSigueTokensStakedEvent(
  config: Omit<UseContractEventConfig<typeof sigueABI, 'TokensStaked'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueABI, eventName: 'TokensStaked', ...config } as UseContractEventConfig<
    typeof sigueABI,
    'TokensStaked'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link sigueErc20ABI}__.
 */
export function useSigueErc20(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: sigueErc20ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__.
 */
export function useSigueErc20Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useSigueErc20Allowance(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'allowance'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'allowance', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'allowance'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useSigueErc20BalanceOf(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useSigueErc20Decimals(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'decimals'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'decimals', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'decimals'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"name"`.
 */
export function useSigueErc20Name(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'name'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'name'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"owner"`.
 */
export function useSigueErc20Owner(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'owner'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'owner', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'owner'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useSigueErc20Symbol(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'symbol'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'symbol'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useSigueErc20TotalSupply(
  config: Omit<UseContractReadConfig<typeof sigueErc20ABI, 'totalSupply'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: sigueErc20ABI, functionName: 'totalSupply', ...config } as UseContractReadConfig<
    typeof sigueErc20ABI,
    'totalSupply'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__.
 */
export function useSigueErc20Write<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueErc20ABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, TFunctionName>({ abi: sigueErc20ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useSigueErc20Approve<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueErc20ABI, 'approve'>['abi'], 'approve'> & {
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'approve'>({
    abi: sigueErc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useSigueErc20DecreaseAllowance<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueErc20ABI, 'decreaseAllowance'>['abi'],
        'decreaseAllowance'
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'decreaseAllowance'> & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'decreaseAllowance'>({
    abi: sigueErc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useSigueErc20IncreaseAllowance<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueErc20ABI, 'increaseAllowance'>['abi'],
        'increaseAllowance'
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'increaseAllowance'> & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'increaseAllowance'>({
    abi: sigueErc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"mint"`.
 */
export function useSigueErc20Mint<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueErc20ABI, 'mint'>['abi'], 'mint'> & {
        functionName?: 'mint'
      }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'mint'> & {
        abi?: never
        functionName?: 'mint'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'mint'>({
    abi: sigueErc20ABI,
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useSigueErc20RenounceOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueErc20ABI, 'renounceOwnership'>['abi'],
        'renounceOwnership'
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'renounceOwnership'> & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'renounceOwnership'>({
    abi: sigueErc20ABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useSigueErc20Transfer<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof sigueErc20ABI, 'transfer'>['abi'], 'transfer'> & {
        functionName?: 'transfer'
      }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'transfer'> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'transfer'>({
    abi: sigueErc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useSigueErc20TransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueErc20ABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'transferFrom'>({
    abi: sigueErc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useSigueErc20TransferOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof sigueErc20ABI, 'transferOwnership'>['abi'],
        'transferOwnership'
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<TMode, typeof sigueErc20ABI, 'transferOwnership'> & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof sigueErc20ABI, 'transferOwnership'>({
    abi: sigueErc20ABI,
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__.
 */
export function usePrepareSigueErc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueErc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: sigueErc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof sigueErc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareSigueErc20Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareSigueErc20DecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareSigueErc20IncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareSigueErc20Mint(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'mint'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareSigueErc20RenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareSigueErc20Transfer(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transfer'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareSigueErc20TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link sigueErc20ABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareSigueErc20TransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: sigueErc20ABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof sigueErc20ABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueErc20ABI}__.
 */
export function useSigueErc20Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof sigueErc20ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: sigueErc20ABI, ...config } as UseContractEventConfig<typeof sigueErc20ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueErc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useSigueErc20ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof sigueErc20ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueErc20ABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof sigueErc20ABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueErc20ABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useSigueErc20OwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof sigueErc20ABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({
    abi: sigueErc20ABI,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof sigueErc20ABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link sigueErc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useSigueErc20TransferEvent(
  config: Omit<UseContractEventConfig<typeof sigueErc20ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: sigueErc20ABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof sigueErc20ABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnable(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ownableABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnableRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ownableABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ownableABI, ...config } as UseContractReadConfig<typeof ownableABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"owner"`.
 */
export function useOwnableOwner(
  config: Omit<UseContractReadConfig<typeof ownableABI, 'owner'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ownableABI, functionName: 'owner', ...config } as UseContractReadConfig<
    typeof ownableABI,
    'owner'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnableWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ownableABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ownableABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ownableABI, TFunctionName>({ abi: ownableABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useOwnableRenounceOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ownableABI, 'renounceOwnership'>['abi'],
        'renounceOwnership'
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<TMode, typeof ownableABI, 'renounceOwnership'> & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ownableABI, 'renounceOwnership'>({
    abi: ownableABI,
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useOwnableTransferOwnership<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ownableABI, 'transferOwnership'>['abi'],
        'transferOwnership'
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<TMode, typeof ownableABI, 'transferOwnership'> & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ownableABI, 'transferOwnership'>({
    abi: ownableABI,
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__.
 */
export function usePrepareOwnableWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ownableABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ownableABI, ...config } as UsePrepareContractWriteConfig<
    typeof ownableABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareOwnableRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableABI, 'renounceOwnership'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ownableABI,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ownableABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareOwnableTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ownableABI, 'transferOwnership'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ownableABI,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ownableABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ownableABI}__.
 */
export function useOwnableEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ownableABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: ownableABI, ...config } as UseContractEventConfig<typeof ownableABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ownableABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useOwnableOwnershipTransferredEvent(
  config: Omit<UseContractEventConfig<typeof ownableABI, 'OwnershipTransferred'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ownableABI, eventName: 'OwnershipTransferred', ...config } as UseContractEventConfig<
    typeof ownableABI,
    'OwnershipTransferred'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link erc721ABI}__.
 */
export function useErc721(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: erc721ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__.
 */
export function useErc721Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof erc721ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, ...config } as UseContractReadConfig<typeof erc721ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc721BalanceOf(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"getApproved"`.
 */
export function useErc721GetApproved(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'getApproved'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'getApproved', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'getApproved'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"isApprovedForAll"`.
 */
export function useErc721IsApprovedForAll(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'isApprovedForAll'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'isApprovedForAll', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'isApprovedForAll'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"name"`.
 */
export function useErc721Name(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'name'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'name'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"ownerOf"`.
 */
export function useErc721OwnerOf(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'ownerOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'ownerOf', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'ownerOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useErc721SupportsInterface(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'supportsInterface'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'supportsInterface', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'supportsInterface'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc721Symbol(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'symbol'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'symbol'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"tokenURI"`.
 */
export function useErc721TokenUri(
  config: Omit<UseContractReadConfig<typeof erc721ABI, 'tokenURI'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc721ABI, functionName: 'tokenURI', ...config } as UseContractReadConfig<
    typeof erc721ABI,
    'tokenURI'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc721ABI}__.
 */
export function useErc721Write<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof erc721ABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof erc721ABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc721ABI, TFunctionName>({ abi: erc721ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc721Approve<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof erc721ABI, 'approve'>['abi'], 'approve'> & {
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof erc721ABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc721ABI, 'approve'>({
    abi: erc721ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function useErc721SafeTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc721ABI, 'safeTransferFrom'>['abi'],
        'safeTransferFrom'
      > & { functionName?: 'safeTransferFrom' }
    : UseContractWriteConfig<TMode, typeof erc721ABI, 'safeTransferFrom'> & {
        abi?: never
        functionName?: 'safeTransferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc721ABI, 'safeTransferFrom'>({
    abi: erc721ABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function useErc721SetApprovalForAll<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc721ABI, 'setApprovalForAll'>['abi'],
        'setApprovalForAll'
      > & { functionName?: 'setApprovalForAll' }
    : UseContractWriteConfig<TMode, typeof erc721ABI, 'setApprovalForAll'> & {
        abi?: never
        functionName?: 'setApprovalForAll'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc721ABI, 'setApprovalForAll'>({
    abi: erc721ABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc721TransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc721ABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof erc721ABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc721ABI, 'transferFrom'>({
    abi: erc721ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc721ABI}__.
 */
export function usePrepareErc721Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof erc721ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: erc721ABI, ...config } as UsePrepareContractWriteConfig<
    typeof erc721ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc721Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof erc721ABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc721ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc721ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function usePrepareErc721SafeTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof erc721ABI, 'safeTransferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc721ABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc721ABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function usePrepareErc721SetApprovalForAll(
  config: Omit<UsePrepareContractWriteConfig<typeof erc721ABI, 'setApprovalForAll'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc721ABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc721ABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc721ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc721TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof erc721ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc721ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc721ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc721ABI}__.
 */
export function useErc721Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof erc721ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: erc721ABI, ...config } as UseContractEventConfig<typeof erc721ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc721ABI}__ and `eventName` set to `"Approval"`.
 */
export function useErc721ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof erc721ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: erc721ABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof erc721ABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc721ABI}__ and `eventName` set to `"ApprovalForAll"`.
 */
export function useErc721ApprovalForAllEvent(
  config: Omit<UseContractEventConfig<typeof erc721ABI, 'ApprovalForAll'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: erc721ABI, eventName: 'ApprovalForAll', ...config } as UseContractEventConfig<
    typeof erc721ABI,
    'ApprovalForAll'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc721ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useErc721TransferEvent(
  config: Omit<UseContractEventConfig<typeof erc721ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: erc721ABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof erc721ABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc721ABI}__.
 */
export function useIerc721(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc721ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__.
 */
export function useIerc721Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, ...config } as UseContractReadConfig<typeof ierc721ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc721BalanceOf(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof ierc721ABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"getApproved"`.
 */
export function useIerc721GetApproved(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, 'getApproved'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, functionName: 'getApproved', ...config } as UseContractReadConfig<
    typeof ierc721ABI,
    'getApproved'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"isApprovedForAll"`.
 */
export function useIerc721IsApprovedForAll(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, 'isApprovedForAll'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, functionName: 'isApprovedForAll', ...config } as UseContractReadConfig<
    typeof ierc721ABI,
    'isApprovedForAll'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"ownerOf"`.
 */
export function useIerc721OwnerOf(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, 'ownerOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, functionName: 'ownerOf', ...config } as UseContractReadConfig<
    typeof ierc721ABI,
    'ownerOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIerc721SupportsInterface(
  config: Omit<UseContractReadConfig<typeof ierc721ABI, 'supportsInterface'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721ABI, functionName: 'supportsInterface', ...config } as UseContractReadConfig<
    typeof ierc721ABI,
    'supportsInterface'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ABI}__.
 */
export function useIerc721Write<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc721ABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ierc721ABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ABI, TFunctionName>({ abi: ierc721ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc721Approve<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc721ABI, 'approve'>['abi'], 'approve'> & {
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof ierc721ABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ABI, 'approve'>({
    abi: ierc721ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function useIerc721SafeTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721ABI, 'safeTransferFrom'>['abi'],
        'safeTransferFrom'
      > & { functionName?: 'safeTransferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc721ABI, 'safeTransferFrom'> & {
        abi?: never
        functionName?: 'safeTransferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ABI, 'safeTransferFrom'>({
    abi: ierc721ABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function useIerc721SetApprovalForAll<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721ABI, 'setApprovalForAll'>['abi'],
        'setApprovalForAll'
      > & { functionName?: 'setApprovalForAll' }
    : UseContractWriteConfig<TMode, typeof ierc721ABI, 'setApprovalForAll'> & {
        abi?: never
        functionName?: 'setApprovalForAll'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ABI, 'setApprovalForAll'>({
    abi: ierc721ABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc721TransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721ABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc721ABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ABI, 'transferFrom'>({
    abi: ierc721ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ABI}__.
 */
export function usePrepareIerc721Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ierc721ABI, ...config } as UsePrepareContractWriteConfig<
    typeof ierc721ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc721Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721ABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function usePrepareIerc721SafeTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721ABI, 'safeTransferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721ABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function usePrepareIerc721SetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721ABI, 'setApprovalForAll'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721ABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc721TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721ABI}__.
 */
export function useIerc721Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ierc721ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: ierc721ABI, ...config } as UseContractEventConfig<typeof ierc721ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721ABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc721ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof ierc721ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721ABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof ierc721ABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721ABI}__ and `eventName` set to `"ApprovalForAll"`.
 */
export function useIerc721ApprovalForAllEvent(
  config: Omit<UseContractEventConfig<typeof ierc721ABI, 'ApprovalForAll'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721ABI, eventName: 'ApprovalForAll', ...config } as UseContractEventConfig<
    typeof ierc721ABI,
    'ApprovalForAll'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc721TransferEvent(
  config: Omit<UseContractEventConfig<typeof ierc721ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721ABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof ierc721ABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc721ReceiverABI}__.
 */
export function useIerc721Receiver(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc721ReceiverABI, ...config })
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__.
 */
export function useIerc721ReceiverWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc721ReceiverABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ierc721ReceiverABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ReceiverABI, TFunctionName>({
    abi: ierc721ReceiverABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function useIerc721ReceiverOnErc721Received<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721ReceiverABI, 'onERC721Received'>['abi'],
        'onERC721Received'
      > & { functionName?: 'onERC721Received' }
    : UseContractWriteConfig<TMode, typeof ierc721ReceiverABI, 'onERC721Received'> & {
        abi?: never
        functionName?: 'onERC721Received'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721ReceiverABI, 'onERC721Received'>({
    abi: ierc721ReceiverABI,
    functionName: 'onERC721Received',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__.
 */
export function usePrepareIerc721ReceiverWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ierc721ReceiverABI, ...config } as UsePrepareContractWriteConfig<
    typeof ierc721ReceiverABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721ReceiverABI}__ and `functionName` set to `"onERC721Received"`.
 */
export function usePrepareIerc721ReceiverOnErc721Received(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, 'onERC721Received'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721ReceiverABI,
    functionName: 'onERC721Received',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721ReceiverABI, 'onERC721Received'>)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: erc20ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof erc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<typeof erc20ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20Allowance(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'allowance'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'allowance', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'allowance'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BalanceOf(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20Decimals(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'decimals'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'decimals', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'decimals'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"name"`.
 */
export function useErc20Name(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'name'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'name'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20Symbol(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'symbol'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'symbol'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20TotalSupply(
  config: Omit<UseContractReadConfig<typeof erc20ABI, 'totalSupply'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc20ABI, functionName: 'totalSupply', ...config } as UseContractReadConfig<
    typeof erc20ABI,
    'totalSupply'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof erc20ABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof erc20ABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, TFunctionName>({ abi: erc20ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20Approve<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof erc20ABI, 'approve'>['abi'], 'approve'> & {
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof erc20ABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, 'approve'>({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20DecreaseAllowance<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc20ABI, 'decreaseAllowance'>['abi'],
        'decreaseAllowance'
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<TMode, typeof erc20ABI, 'decreaseAllowance'> & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, 'decreaseAllowance'>({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20IncreaseAllowance<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc20ABI, 'increaseAllowance'>['abi'],
        'increaseAllowance'
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<TMode, typeof erc20ABI, 'increaseAllowance'> & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, 'increaseAllowance'>({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20Transfer<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof erc20ABI, 'transfer'>['abi'], 'transfer'> & {
        functionName?: 'transfer'
      }
    : UseContractWriteConfig<TMode, typeof erc20ABI, 'transfer'> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, 'transfer'>({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20TransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof erc20ABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof erc20ABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof erc20ABI, 'transferFrom'>({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function usePrepareErc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: erc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({ abi: erc20ABI, functionName: 'approve', ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    'approve'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20DecreaseAllowance(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20IncreaseAllowance(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20Transfer(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof erc20ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: erc20ABI, ...config } as UseContractEventConfig<typeof erc20ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useErc20ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: erc20ABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof erc20ABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useErc20TransferEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: erc20ABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof erc20ABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc20ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ierc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ierc20ABI, ...config } as UseContractReadConfig<typeof ierc20ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20Allowance(
  config: Omit<UseContractReadConfig<typeof ierc20ABI, 'allowance'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20ABI, functionName: 'allowance', ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    'allowance'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20BalanceOf(
  config: Omit<UseContractReadConfig<typeof ierc20ABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20ABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20TotalSupply(
  config: Omit<UseContractReadConfig<typeof ierc20ABI, 'totalSupply'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20ABI, functionName: 'totalSupply', ...config } as UseContractReadConfig<
    typeof ierc20ABI,
    'totalSupply'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Write<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc20ABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ierc20ABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20ABI, TFunctionName>({ abi: ierc20ABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20Approve<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc20ABI, 'approve'>['abi'], 'approve'> & {
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof ierc20ABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20ABI, 'approve'>({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20Transfer<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc20ABI, 'transfer'>['abi'], 'transfer'> & {
        functionName?: 'transfer'
      }
    : UseContractWriteConfig<TMode, typeof ierc20ABI, 'transfer'> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20ABI, 'transfer'>({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20TransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc20ABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc20ABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20ABI, 'transferFrom'>({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function usePrepareIerc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ierc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof ierc20ABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20Approve(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20Transfer(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20TransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20ABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__.
 */
export function useIerc20Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ierc20ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: ierc20ABI, ...config } as UseContractEventConfig<typeof ierc20ABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof ierc20ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc20ABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof ierc20ABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20TransferEvent(
  config: Omit<UseContractEventConfig<typeof ierc20ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc20ABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof ierc20ABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link erc165ABI}__.
 */
export function useErc165(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: erc165ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc165ABI}__.
 */
export function useErc165Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof erc165ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: erc165ABI, ...config } as UseContractReadConfig<typeof erc165ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc165ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useErc165SupportsInterface(
  config: Omit<UseContractReadConfig<typeof erc165ABI, 'supportsInterface'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: erc165ABI, functionName: 'supportsInterface', ...config } as UseContractReadConfig<
    typeof erc165ABI,
    'supportsInterface'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc165ABI}__.
 */
export function useIerc165(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc165ABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc165ABI}__.
 */
export function useIerc165Read<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ierc165ABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ierc165ABI, ...config } as UseContractReadConfig<typeof ierc165ABI, TFunctionName>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc165ABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIerc165SupportsInterface(
  config: Omit<UseContractReadConfig<typeof ierc165ABI, 'supportsInterface'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc165ABI, functionName: 'supportsInterface', ...config } as UseContractReadConfig<
    typeof ierc165ABI,
    'supportsInterface'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc721MetadataABI}__.
 */
export function useIerc721Metadata(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc721MetadataABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__.
 */
export function useIerc721MetadataRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc721MetadataBalanceOf(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"getApproved"`.
 */
export function useIerc721MetadataGetApproved(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'getApproved'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'getApproved', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'getApproved'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"isApprovedForAll"`.
 */
export function useIerc721MetadataIsApprovedForAll(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'isApprovedForAll'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({
    abi: ierc721MetadataABI,
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof ierc721MetadataABI, 'isApprovedForAll'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"name"`.
 */
export function useIerc721MetadataName(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'name'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'name'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"ownerOf"`.
 */
export function useIerc721MetadataOwnerOf(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'ownerOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'ownerOf', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'ownerOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"supportsInterface"`.
 */
export function useIerc721MetadataSupportsInterface(
  config: Omit<
    UseContractReadConfig<typeof ierc721MetadataABI, 'supportsInterface'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: ierc721MetadataABI,
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof ierc721MetadataABI, 'supportsInterface'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"symbol"`.
 */
export function useIerc721MetadataSymbol(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'symbol'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'symbol'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"tokenURI"`.
 */
export function useIerc721MetadataTokenUri(
  config: Omit<UseContractReadConfig<typeof ierc721MetadataABI, 'tokenURI'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc721MetadataABI, functionName: 'tokenURI', ...config } as UseContractReadConfig<
    typeof ierc721MetadataABI,
    'tokenURI'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__.
 */
export function useIerc721MetadataWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc721MetadataABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ierc721MetadataABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721MetadataABI, TFunctionName>({
    abi: ierc721MetadataABI,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc721MetadataApprove<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721MetadataABI, 'approve'>['abi'],
        'approve'
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<TMode, typeof ierc721MetadataABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721MetadataABI, 'approve'>({
    abi: ierc721MetadataABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function useIerc721MetadataSafeTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721MetadataABI, 'safeTransferFrom'>['abi'],
        'safeTransferFrom'
      > & { functionName?: 'safeTransferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc721MetadataABI, 'safeTransferFrom'> & {
        abi?: never
        functionName?: 'safeTransferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721MetadataABI, 'safeTransferFrom'>({
    abi: ierc721MetadataABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function useIerc721MetadataSetApprovalForAll<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721MetadataABI, 'setApprovalForAll'>['abi'],
        'setApprovalForAll'
      > & { functionName?: 'setApprovalForAll' }
    : UseContractWriteConfig<TMode, typeof ierc721MetadataABI, 'setApprovalForAll'> & {
        abi?: never
        functionName?: 'setApprovalForAll'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721MetadataABI, 'setApprovalForAll'>({
    abi: ierc721MetadataABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc721MetadataTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc721MetadataABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc721MetadataABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc721MetadataABI, 'transferFrom'>({
    abi: ierc721MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__.
 */
export function usePrepareIerc721MetadataWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721MetadataABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ierc721MetadataABI, ...config } as UsePrepareContractWriteConfig<
    typeof ierc721MetadataABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc721MetadataApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721MetadataABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"safeTransferFrom"`.
 */
export function usePrepareIerc721MetadataSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'safeTransferFrom'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721MetadataABI,
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"setApprovalForAll"`.
 */
export function usePrepareIerc721MetadataSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'setApprovalForAll'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721MetadataABI,
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc721MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc721MetadataTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc721MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc721MetadataABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721MetadataABI}__.
 */
export function useIerc721MetadataEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ierc721MetadataABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: ierc721MetadataABI, ...config } as UseContractEventConfig<
    typeof ierc721MetadataABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721MetadataABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc721MetadataApprovalEvent(
  config: Omit<UseContractEventConfig<typeof ierc721MetadataABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721MetadataABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof ierc721MetadataABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721MetadataABI}__ and `eventName` set to `"ApprovalForAll"`.
 */
export function useIerc721MetadataApprovalForAllEvent(
  config: Omit<UseContractEventConfig<typeof ierc721MetadataABI, 'ApprovalForAll'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721MetadataABI, eventName: 'ApprovalForAll', ...config } as UseContractEventConfig<
    typeof ierc721MetadataABI,
    'ApprovalForAll'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc721MetadataABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc721MetadataTransferEvent(
  config: Omit<UseContractEventConfig<typeof ierc721MetadataABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc721MetadataABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof ierc721MetadataABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20Metadata(config: Omit<UseContractConfig, 'abi'> = {} as any) {
  return useContract({ abi: ierc20MetadataABI, ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, TFunctionName>, 'abi'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"allowance"`.
 */
export function useIerc20MetadataAllowance(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'allowance'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'allowance', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'allowance'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useIerc20MetadataBalanceOf(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'balanceOf'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'balanceOf', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'balanceOf'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"decimals"`.
 */
export function useIerc20MetadataDecimals(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'decimals'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'decimals', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'decimals'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"name"`.
 */
export function useIerc20MetadataName(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'name'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'name', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'name'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"symbol"`.
 */
export function useIerc20MetadataSymbol(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'symbol'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'symbol', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'symbol'
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useIerc20MetadataTotalSupply(
  config: Omit<UseContractReadConfig<typeof ierc20MetadataABI, 'totalSupply'>, 'abi' | 'functionName'> = {} as any
) {
  return useContractRead({ abi: ierc20MetadataABI, functionName: 'totalSupply', ...config } as UseContractReadConfig<
    typeof ierc20MetadataABI,
    'totalSupply'
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataWrite<TMode extends WriteContractMode, TFunctionName extends string>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof ierc20MetadataABI, string>['abi'], TFunctionName>
    : UseContractWriteConfig<TMode, typeof ierc20MetadataABI, TFunctionName> & {
        abi?: never
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20MetadataABI, TFunctionName>({ abi: ierc20MetadataABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function useIerc20MetadataApprove<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc20MetadataABI, 'approve'>['abi'],
        'approve'
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<TMode, typeof ierc20MetadataABI, 'approve'> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20MetadataABI, 'approve'>({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function useIerc20MetadataTransfer<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc20MetadataABI, 'transfer'>['abi'],
        'transfer'
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<TMode, typeof ierc20MetadataABI, 'transfer'> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20MetadataABI, 'transfer'>({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useIerc20MetadataTransferFrom<TMode extends WriteContractMode>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof ierc20MetadataABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof ierc20MetadataABI, 'transferFrom'> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof ierc20MetadataABI, 'transferFrom'>({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function usePrepareIerc20MetadataWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20MetadataABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: ierc20MetadataABI, ...config } as UsePrepareContractWriteConfig<
    typeof ierc20MetadataABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareIerc20MetadataApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareIerc20MetadataTransfer(
  config: Omit<UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>, 'abi' | 'functionName'> = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ierc20MetadataABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareIerc20MetadataTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: ierc20MetadataABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ierc20MetadataABI, 'transferFrom'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__.
 */
export function useIerc20MetadataEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof ierc20MetadataABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: ierc20MetadataABI, ...config } as UseContractEventConfig<
    typeof ierc20MetadataABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__ and `eventName` set to `"Approval"`.
 */
export function useIerc20MetadataApprovalEvent(
  config: Omit<UseContractEventConfig<typeof ierc20MetadataABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc20MetadataABI, eventName: 'Approval', ...config } as UseContractEventConfig<
    typeof ierc20MetadataABI,
    'Approval'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ierc20MetadataABI}__ and `eventName` set to `"Transfer"`.
 */
export function useIerc20MetadataTransferEvent(
  config: Omit<UseContractEventConfig<typeof ierc20MetadataABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({ abi: ierc20MetadataABI, eventName: 'Transfer', ...config } as UseContractEventConfig<
    typeof ierc20MetadataABI,
    'Transfer'
  >)
}

/**
 * Wraps __{@link useContract}__ with `abi` set to __{@link lensHubABI}__.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHub(
  config: Omit<UseContractConfig, 'abi' | 'address'> & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return useContract({ abi: lensHubABI, address: lensHubAddress[80001], ...config })
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubRead<TFunctionName extends string>(
  config: Omit<UseContractReadConfig<typeof lensHubABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({ abi: lensHubABI, address: lensHubAddress[80001], ...config } as UseContractReadConfig<
    typeof lensHubABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"balanceOf"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubBalanceOf(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'balanceOf'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'balanceOf'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"defaultProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubDefaultProfile(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'defaultProfile'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'defaultProfile',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'defaultProfile'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"exists"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubExists(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'exists'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'exists',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'exists'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getApproved"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetApproved(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getApproved'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getApproved'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getCollectModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetCollectModule(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getCollectModule'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getCollectModule',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getCollectModule'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getCollectNFT"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetCollectNft(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getCollectNFT'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getCollectNFT',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getCollectNFT'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getCollectNFTImpl"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetCollectNftImpl(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getCollectNFTImpl'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getCollectNFTImpl',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getCollectNFTImpl'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getContentURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetContentUri(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getContentURI'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getContentURI',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getContentURI'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getDispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetDispatcher(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getDispatcher'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getDispatcher',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getDispatcher'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getDomainSeparator"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetDomainSeparator(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getDomainSeparator'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getDomainSeparator',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getDomainSeparator'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getFollowModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetFollowModule(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getFollowModule'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getFollowModule',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getFollowModule'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getFollowNFT"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetFollowNft(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getFollowNFT'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getFollowNFT',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getFollowNFT'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getFollowNFTImpl"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetFollowNftImpl(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getFollowNFTImpl'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getFollowNFTImpl',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getFollowNFTImpl'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getFollowNFTURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetFollowNfturi(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getFollowNFTURI'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getFollowNFTURI',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getFollowNFTURI'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getGovernance"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetGovernance(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getGovernance'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getGovernance',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getGovernance'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getHandle"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetHandle(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getHandle'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getHandle',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getHandle'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetProfile(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getProfile'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getProfile',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getProfile'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getProfileIdByHandle"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetProfileIdByHandle(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getProfileIdByHandle'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getProfileIdByHandle',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getProfileIdByHandle'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getPub"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetPub(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getPub'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getPub',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getPub'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getPubCount"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetPubCount(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getPubCount'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getPubCount',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getPubCount'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getPubPointer"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetPubPointer(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getPubPointer'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getPubPointer',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getPubPointer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getPubType"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetPubType(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getPubType'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getPubType',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getPubType'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getReferenceModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetReferenceModule(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getReferenceModule'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getReferenceModule',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getReferenceModule'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"getState"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubGetState(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'getState'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'getState',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'getState'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubIsApprovedForAll(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'isApprovedForAll'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'isApprovedForAll'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"isCollectModuleWhitelisted"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubIsCollectModuleWhitelisted(
  config: Omit<
    UseContractReadConfig<typeof lensHubABI, 'isCollectModuleWhitelisted'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'isCollectModuleWhitelisted',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'isCollectModuleWhitelisted'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"isFollowModuleWhitelisted"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubIsFollowModuleWhitelisted(
  config: Omit<
    UseContractReadConfig<typeof lensHubABI, 'isFollowModuleWhitelisted'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'isFollowModuleWhitelisted',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'isFollowModuleWhitelisted'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"isProfileCreatorWhitelisted"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubIsProfileCreatorWhitelisted(
  config: Omit<
    UseContractReadConfig<typeof lensHubABI, 'isProfileCreatorWhitelisted'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'isProfileCreatorWhitelisted',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'isProfileCreatorWhitelisted'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"isReferenceModuleWhitelisted"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubIsReferenceModuleWhitelisted(
  config: Omit<
    UseContractReadConfig<typeof lensHubABI, 'isReferenceModuleWhitelisted'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'isReferenceModuleWhitelisted',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'isReferenceModuleWhitelisted'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mintTimestampOf"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubMintTimestampOf(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'mintTimestampOf'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mintTimestampOf',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'mintTimestampOf'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"name"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubName(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'name'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'name'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"ownerOf"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubOwnerOf(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'ownerOf'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'ownerOf'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"sigNonces"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSigNonces(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'sigNonces'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'sigNonces',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'sigNonces'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSupportsInterface(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'supportsInterface'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'supportsInterface'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"symbol"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSymbol(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'symbol'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'symbol'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"tokenByIndex"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTokenByIndex(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'tokenByIndex'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'tokenByIndex',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'tokenByIndex'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"tokenDataOf"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTokenDataOf(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'tokenDataOf'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'tokenDataOf',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'tokenDataOf'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"tokenOfOwnerByIndex"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTokenOfOwnerByIndex(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'tokenOfOwnerByIndex'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'tokenOfOwnerByIndex',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'tokenOfOwnerByIndex'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"tokenURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTokenUri(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'tokenURI'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'tokenURI'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"totalSupply"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTotalSupply(
  config: Omit<UseContractReadConfig<typeof lensHubABI, 'totalSupply'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractRead({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof lensHubABI, 'totalSupply'>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubWrite<
  TMode extends WriteContractMode,
  TFunctionName extends string,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, string>['abi'], TFunctionName> & {
        address?: `0x${string}`
        chainId?: TChainId
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, TFunctionName> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, TFunctionName>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubApprove<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'approve'>['abi'], 'approve'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'approve'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'approve'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'approve'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubBurn<TMode extends WriteContractMode, TChainId extends number = keyof typeof lensHubAddress>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'burn'>['abi'], 'burn'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'burn'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'burn'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burn'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'burn'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'burn',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"burnWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubBurnWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'burnWithSig'>['abi'],
        'burnWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'burnWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'burnWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'burnWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'burnWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'burnWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"collect"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubCollect<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'collect'>['abi'], 'collect'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'collect'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'collect'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'collect'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'collect'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'collect',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"collectWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubCollectWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'collectWithSig'>['abi'],
        'collectWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'collectWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'collectWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'collectWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'collectWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'collectWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"comment"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubComment<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'comment'>['abi'], 'comment'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'comment'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'comment'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'comment'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'comment'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'comment',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"commentWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubCommentWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'commentWithSig'>['abi'],
        'commentWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'commentWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'commentWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'commentWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'commentWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'commentWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"commentWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubCommentWithSigDispatcher<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'commentWithSig_Dispatcher'>['abi'],
        'commentWithSig_Dispatcher'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'commentWithSig_Dispatcher' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'commentWithSig_Dispatcher'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'commentWithSig_Dispatcher'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'commentWithSig_Dispatcher'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'commentWithSig_Dispatcher',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"createProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubCreateProfile<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'createProfile'>['abi'],
        'createProfile'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'createProfile' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'createProfile'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createProfile'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'createProfile'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'createProfile',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"emitCollectNFTTransferEvent"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubEmitCollectNftTransferEvent<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'emitCollectNFTTransferEvent'>['abi'],
        'emitCollectNFTTransferEvent'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'emitCollectNFTTransferEvent' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'emitCollectNFTTransferEvent'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'emitCollectNFTTransferEvent'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'emitCollectNFTTransferEvent'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'emitCollectNFTTransferEvent',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"emitFollowNFTTransferEvent"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubEmitFollowNftTransferEvent<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'emitFollowNFTTransferEvent'>['abi'],
        'emitFollowNFTTransferEvent'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'emitFollowNFTTransferEvent' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'emitFollowNFTTransferEvent'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'emitFollowNFTTransferEvent'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'emitFollowNFTTransferEvent'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'emitFollowNFTTransferEvent',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"follow"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubFollow<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'follow'>['abi'], 'follow'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'follow'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'follow'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'follow'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'follow'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'follow',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"followWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubFollowWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'followWithSig'>['abi'],
        'followWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'followWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'followWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'followWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'followWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'followWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"initialize"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubInitialize<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'initialize'>['abi'],
        'initialize'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'initialize' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'initialize'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'initialize'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'initialize'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirror"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubMirror<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'mirror'>['abi'], 'mirror'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'mirror'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'mirror'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mirror'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'mirror'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirror',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirrorWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubMirrorWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'mirrorWithSig'>['abi'],
        'mirrorWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'mirrorWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'mirrorWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mirrorWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'mirrorWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirrorWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirrorWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubMirrorWithSigDispatcher<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'mirrorWithSig_Dispatcher'>['abi'],
        'mirrorWithSig_Dispatcher'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'mirrorWithSig_Dispatcher' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'mirrorWithSig_Dispatcher'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mirrorWithSig_Dispatcher'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'mirrorWithSig_Dispatcher'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirrorWithSig_Dispatcher',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"permit"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubPermit<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'permit'>['abi'], 'permit'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'permit'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'permit'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'permit'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'permit'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'permit',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"permitForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubPermitForAll<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'permitForAll'>['abi'],
        'permitForAll'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'permitForAll' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'permitForAll'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'permitForAll'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'permitForAll'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'permitForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"post"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubPost<TMode extends WriteContractMode, TChainId extends number = keyof typeof lensHubAddress>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'post'>['abi'], 'post'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'post'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'post'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'post'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'post'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'post',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"postWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubPostWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'postWithSig'>['abi'],
        'postWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'postWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'postWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'postWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'postWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'postWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"postWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubPostWithSigDispatcher<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'postWithSig_Dispatcher'>['abi'],
        'postWithSig_Dispatcher'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'postWithSig_Dispatcher' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'postWithSig_Dispatcher'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'postWithSig_Dispatcher'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'postWithSig_Dispatcher'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'postWithSig_Dispatcher',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSafeTransferFrom<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'safeTransferFrom'>['abi'],
        'safeTransferFrom'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'safeTransferFrom' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'safeTransferFrom'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'safeTransferFrom'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetApprovalForAll<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setApprovalForAll'>['abi'],
        'setApprovalForAll'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setApprovalForAll' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setApprovalForAll'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setApprovalForAll'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDefaultProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetDefaultProfile<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setDefaultProfile'>['abi'],
        'setDefaultProfile'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setDefaultProfile' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setDefaultProfile'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDefaultProfile'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setDefaultProfile'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDefaultProfile',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDefaultProfileWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetDefaultProfileWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setDefaultProfileWithSig'>['abi'],
        'setDefaultProfileWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setDefaultProfileWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setDefaultProfileWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDefaultProfileWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setDefaultProfileWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDefaultProfileWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetDispatcher<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setDispatcher'>['abi'],
        'setDispatcher'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setDispatcher' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setDispatcher'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDispatcher'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setDispatcher'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDispatcher',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDispatcherWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetDispatcherWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setDispatcherWithSig'>['abi'],
        'setDispatcherWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setDispatcherWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setDispatcherWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setDispatcherWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setDispatcherWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDispatcherWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setEmergencyAdmin"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetEmergencyAdmin<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setEmergencyAdmin'>['abi'],
        'setEmergencyAdmin'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setEmergencyAdmin' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setEmergencyAdmin'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setEmergencyAdmin'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setEmergencyAdmin'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setEmergencyAdmin',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetFollowModule<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setFollowModule'>['abi'],
        'setFollowModule'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setFollowModule' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setFollowModule'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setFollowModule'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setFollowModule'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowModuleWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetFollowModuleWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setFollowModuleWithSig'>['abi'],
        'setFollowModuleWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setFollowModuleWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setFollowModuleWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setFollowModuleWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setFollowModuleWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowModuleWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowNFTURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetFollowNfturi<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setFollowNFTURI'>['abi'],
        'setFollowNFTURI'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setFollowNFTURI' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setFollowNFTURI'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setFollowNFTURI'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setFollowNFTURI'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowNFTURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowNFTURIWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetFollowNfturiWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setFollowNFTURIWithSig'>['abi'],
        'setFollowNFTURIWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setFollowNFTURIWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setFollowNFTURIWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setFollowNFTURIWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setFollowNFTURIWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowNFTURIWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setGovernance"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetGovernance<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setGovernance'>['abi'],
        'setGovernance'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setGovernance' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setGovernance'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setGovernance'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setGovernance'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setGovernance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setProfileImageURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetProfileImageUri<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setProfileImageURI'>['abi'],
        'setProfileImageURI'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setProfileImageURI' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setProfileImageURI'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setProfileImageURI'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setProfileImageURI'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setProfileImageURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setProfileImageURIWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetProfileImageUriWithSig<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'setProfileImageURIWithSig'>['abi'],
        'setProfileImageURIWithSig'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'setProfileImageURIWithSig' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setProfileImageURIWithSig'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setProfileImageURIWithSig'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setProfileImageURIWithSig'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setProfileImageURIWithSig',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setState"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubSetState<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<TMode, PrepareWriteContractResult<typeof lensHubABI, 'setState'>['abi'], 'setState'> & {
        address?: `0x${string}`
        chainId?: TChainId
        functionName?: 'setState'
      }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'setState'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setState'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'setState'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setState',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTransferFrom<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'transferFrom'>['abi'],
        'transferFrom'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'transferFrom' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'transferFrom'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'transferFrom'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistCollectModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubWhitelistCollectModule<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'whitelistCollectModule'>['abi'],
        'whitelistCollectModule'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'whitelistCollectModule' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'whitelistCollectModule'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'whitelistCollectModule'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'whitelistCollectModule'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistCollectModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistFollowModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubWhitelistFollowModule<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'whitelistFollowModule'>['abi'],
        'whitelistFollowModule'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'whitelistFollowModule' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'whitelistFollowModule'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'whitelistFollowModule'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'whitelistFollowModule'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistFollowModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistProfileCreator"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubWhitelistProfileCreator<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'whitelistProfileCreator'>['abi'],
        'whitelistProfileCreator'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'whitelistProfileCreator' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'whitelistProfileCreator'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'whitelistProfileCreator'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'whitelistProfileCreator'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistProfileCreator',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistReferenceModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubWhitelistReferenceModule<
  TMode extends WriteContractMode,
  TChainId extends number = keyof typeof lensHubAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        TMode,
        PrepareWriteContractResult<typeof lensHubABI, 'whitelistReferenceModule'>['abi'],
        'whitelistReferenceModule'
      > & { address?: `0x${string}`; chainId?: TChainId; functionName?: 'whitelistReferenceModule' }
    : UseContractWriteConfig<TMode, typeof lensHubABI, 'whitelistReferenceModule'> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'whitelistReferenceModule'
      } = {} as any
) {
  return useContractWrite<TMode, typeof lensHubABI, 'whitelistReferenceModule'>({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistReferenceModule',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubWrite<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubApprove(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'approve'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"burn"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubBurn(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'burn'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'burn',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'burn'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"burnWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubBurnWithSig(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'burnWithSig'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'burnWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'burnWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"collect"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubCollect(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'collect'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'collect',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'collect'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"collectWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubCollectWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'collectWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'collectWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'collectWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"comment"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubComment(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'comment'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'comment',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'comment'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"commentWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubCommentWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'commentWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'commentWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'commentWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"commentWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubCommentWithSigDispatcher(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'commentWithSig_Dispatcher'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'commentWithSig_Dispatcher',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'commentWithSig_Dispatcher'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"createProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubCreateProfile(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'createProfile'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'createProfile',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'createProfile'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"emitCollectNFTTransferEvent"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubEmitCollectNftTransferEvent(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'emitCollectNFTTransferEvent'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'emitCollectNFTTransferEvent',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'emitCollectNFTTransferEvent'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"emitFollowNFTTransferEvent"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubEmitFollowNftTransferEvent(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'emitFollowNFTTransferEvent'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'emitFollowNFTTransferEvent',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'emitFollowNFTTransferEvent'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"follow"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubFollow(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'follow'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'follow',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'follow'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"followWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubFollowWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'followWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'followWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'followWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"initialize"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubInitialize(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'initialize'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirror"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubMirror(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'mirror'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirror',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'mirror'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirrorWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubMirrorWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'mirrorWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirrorWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'mirrorWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"mirrorWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubMirrorWithSigDispatcher(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'mirrorWithSig_Dispatcher'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'mirrorWithSig_Dispatcher',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'mirrorWithSig_Dispatcher'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"permit"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubPermit(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'permit'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'permit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'permit'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"permitForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubPermitForAll(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'permitForAll'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'permitForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'permitForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"post"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubPost(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'post'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'post',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'post'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"postWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubPostWithSig(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'postWithSig'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'postWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'postWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"postWithSig_Dispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubPostWithSigDispatcher(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'postWithSig_Dispatcher'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'postWithSig_Dispatcher',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'postWithSig_Dispatcher'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDefaultProfile"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetDefaultProfile(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setDefaultProfile'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDefaultProfile',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setDefaultProfile'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDefaultProfileWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetDefaultProfileWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setDefaultProfileWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDefaultProfileWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setDefaultProfileWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDispatcher"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetDispatcher(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setDispatcher'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDispatcher',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setDispatcher'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setDispatcherWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetDispatcherWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setDispatcherWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setDispatcherWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setDispatcherWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setEmergencyAdmin"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetEmergencyAdmin(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setEmergencyAdmin'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setEmergencyAdmin',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setEmergencyAdmin'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetFollowModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowModuleWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetFollowModuleWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowModuleWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowModuleWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowModuleWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowNFTURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetFollowNfturi(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowNFTURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowNFTURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowNFTURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setFollowNFTURIWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetFollowNfturiWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowNFTURIWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setFollowNFTURIWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setFollowNFTURIWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setGovernance"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetGovernance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setGovernance'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setGovernance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setGovernance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setProfileImageURI"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetProfileImageUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setProfileImageURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setProfileImageURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setProfileImageURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setProfileImageURIWithSig"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetProfileImageUriWithSig(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'setProfileImageURIWithSig'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setProfileImageURIWithSig',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setProfileImageURIWithSig'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"setState"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubSetState(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'setState'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'setState',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'setState'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubTransferFrom(
  config: Omit<UsePrepareContractWriteConfig<typeof lensHubABI, 'transferFrom'>, 'abi' | 'address' | 'functionName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistCollectModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubWhitelistCollectModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistCollectModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistCollectModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistCollectModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistFollowModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubWhitelistFollowModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistFollowModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistFollowModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistFollowModule'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistProfileCreator"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubWhitelistProfileCreator(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistProfileCreator'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistProfileCreator',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistProfileCreator'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link lensHubABI}__ and `functionName` set to `"whitelistReferenceModule"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function usePrepareLensHubWhitelistReferenceModule(
  config: Omit<
    UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistReferenceModule'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof lensHubAddress } = {} as any
) {
  return usePrepareContractWrite({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    functionName: 'whitelistReferenceModule',
    ...config,
  } as UsePrepareContractWriteConfig<typeof lensHubABI, 'whitelistReferenceModule'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lensHubABI}__.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof lensHubABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractEvent({ abi: lensHubABI, address: lensHubAddress[80001], ...config } as UseContractEventConfig<
    typeof lensHubABI,
    TEventName
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lensHubABI}__ and `eventName` set to `"Approval"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubApprovalEvent(
  config: Omit<UseContractEventConfig<typeof lensHubABI, 'Approval'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractEvent({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof lensHubABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lensHubABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubApprovalForAllEvent(
  config: Omit<UseContractEventConfig<typeof lensHubABI, 'ApprovalForAll'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractEvent({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof lensHubABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link lensHubABI}__ and `eventName` set to `"Transfer"`.
 *
 * [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x45cf9Ba12b43F6c8B7148E06A6f84c5B9ad3Dd44)
 */
export function useLensHubTransferEvent(
  config: Omit<UseContractEventConfig<typeof lensHubABI, 'Transfer'>, 'abi' | 'address' | 'eventName'> & {
    chainId?: keyof typeof lensHubAddress
  } = {} as any
) {
  return useContractEvent({
    abi: lensHubABI,
    address: lensHubAddress[80001],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof lensHubABI, 'Transfer'>)
}
