import { inputAnatomy } from '@chakra-ui/anatomy';
import { defineStyle, defineStyleConfig, extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { alertTheme } from './alertTheme';
import { cardTheme } from './cardTheme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const lg = defineStyle({
  minH: 20,
});

const sizes = {
  lg: definePartsStyle({ field: lg, addon: lg }),
};

export const inputTheme = defineMultiStyleConfig({ sizes });
export const linkTheme = defineStyleConfig({
  baseStyle: {
    opacity: 0.7,
    fontWeight: 600,
    cursor: 'pointer',
    _hover: {
      textDecoration: 'none',
      opacity: 1,
    },
  },
});

const theme = extendTheme({
  styles: {
    global: () => ({
      'html, body': {
        backgroundColor: 'white',
        overflowX: 'hidden',
        color: '#1a0f00',
        '*::selection': {
          color: '#170b0c',
          background: 'rgba(161, 122, 86, .7)',
        },
      },
    }),
  },
  colors: {
    primary: {
      50: '#e5fded',
      100: '#bcf5cf',
      200: '#93eeb5',
      300: '#68e7a1',
      400: '#42e091',
      500: '#2dc781',
      600: '#219b6c',
      700: '#166f54',
      800: '#0a4337',
      900: '#001712',
    },
    orange: {
      50: '#fdf2e6',
      100: '#efdac2',
      200: '#e4c19d',
      300: '#d8a975',
      400: '#ce914e',
      500: '#b47735',
      600: '#8d5d29',
      700: '#64421c',
      800: '#3d2710',
      900: '#170c00',
    },
    green: {
      50: '#e5fded',
      100: '#bcf5cf',
      200: '#93eeb5',
      300: '#68e7a1',
      400: '#42e091',
      500: '#2dc781',
      600: '#219b6c',
      700: '#166f54',
      800: '#0a4337',
      900: '#001712',
    },
    indigo: {
      50: '#f9f9fd',
      100: '#edecfa',
      200: '#dfdff6',
      300: '#d1d0f2',
      400: '#c1c0ee',
      500: '#afade9',
      600: '#9b98e4',
      700: '#827fdd',
      800: '#605dd4',
      900: '#2b27ad',
    },
    purple: {
      50: '#eae9ff',
      100: '#c1c1f6',
      200: '#9897ea',
      300: '#716ddf',
      400: '#4844d5',
      500: '#2f2abb',
      600: '#232193',
      700: '#18176a',
      800: '#0e0d41',
      900: '#04041c',
    },
  },
  components: {
    Input: inputTheme,
    Link: linkTheme,
    Card: cardTheme,
    Alert: alertTheme,
  },
  fonts: {
    heading: `'forma-djr-display', sans-serif`,
    body: `'Golos Text', sans-serif`,
  },
});

export default theme;
