import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

interface NavLinkProps extends React.ComponentProps<typeof Link> {
  children: React.ReactNode;
  href: string;
}

export default function NavLink({ children, href, ...rest }: NavLinkProps) {
  const { pathname } = useRouter();

  const isActive = pathname === href;

  return (
    <Link
      as={NextLink}
      href={href}
      color={pathname === href ? 'indigo.300' : 'current'}
      background={pathname === href ? 'linear-gradient(315deg, #2e2ab9, #27239c)' : 'transparent'}
      boxShadow={
        pathname === href
          ? `-5px 5px 12px rgba(37, 33, 147, 0.6),
                   -5px -5px 12px rgba(50, 45, 199, 0.6)`
          : 'none'
      }
      py={3}
      px={10}
      w="full"
      fontSize="lg"
      fontWeight={isActive ? 600 : 500}
      opacity={1}
      lineHeight="1.1"
      _hover={{
        color: 'indigo.300',
        backgroundColor: 'rgba(37, 33, 147, 0.6)',
      }}
      {...rest}>
      {children}
    </Link>
  );
}
